import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

export default function useSeleccionTarjeta({ product }) {
  let history = useHistory();

  const [focus, setFocus] = useState();
  const [cargaInicial, setCargaInicial] = useState(true);
  const [tarjeta, setTarjeta] = useState("");
  const [methodId, setMethodId] = useState("");
  const [cuotas, setCuotas] = useState([]);
  const [paymentStutus, setPaymentStatus] = useState(true);

  const validYear = parseInt(
    new Date().getFullYear().toString().substring(2, 4)
  );
  const nameRegex = /^([a-zA-Z]{3,})+( ([a-zA-Z]{3,})+)+$/;
  const justletterRegex = /^([a-zA-Z ])/;
  const numberRegex = /^[0-9]+$/;
  const expiryMonthRegex = /^(0[1-9]|1[0-2])$/;
  const expiryYearRegex = /^[0-9]{2}$/;
  const cvcRegex = /^[0-9]{3,4}$/;
  const phoneRegex =
    /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;

  const tarjetas = [
    { nombre: "Visa", value: "visa", method_id: "1" },
    { nombre: "Visa Debito", value: "visa_debito", method_id: "31" },
    { nombre: "Mastercard", value: "mastercard", method_id: "104" },
    { nombre: "American Express", value: "amex", method_id: "65" },
    { nombre: "Tarjeta Naranja", value: "naranja", method_id: "24" },
  ];

  const documentos = [
    { nombre: "DNI", value: "dni" },
    { nombre: "Cedula de Identidad", value: "ci" },
    { nombre: "Libreta de Enrolamiento", value: "le" },
    { nombre: "Libreta Civica", value: "lc" },
  ];

  const advanceSchema = yup.object().shape({
    tarjeta: yup
      .string()
      .oneOf(["visa", "visa_debito", "mastercard", "amex", "naranja"])
      .required("La tarjeta es requerida"),
    numeroTarjeta: yup
      .string()
      .matches(numberRegex, { message: "Solo numeros" })
      .when("tarjeta", {
        is: "amex",
        then: yup
          .string()
          .min(
            15,
            "El numero de tarjeta debe tener 15 digitos si es American express"
          )
          .max(
            15,
            "El numero de tarjeta debe tener 15 digitos si es American express"
          ),
        otherwise: yup
          .string()
          .min(16, "El numero de tarjeta debe tener 16 digitos")
          .max(16, "El numero de tarjeta debe tener 16 digitos"),
      })
      .trim()
      .required("El numero de tarjeta es requerido"),
    vencimientoMes: yup
      .string()
      .matches(expiryMonthRegex, { message: "vencimiento invalido" })
      .trim()
      .required("el mes es requerido"),
    vencimientoAnio: yup
      .string()
      .matches(expiryYearRegex, { message: "vencimiento invalido" })
      .trim()
      .transform(function (value, originalvalue) {
        return parseInt(value) >= validYear &&
          parseInt(value) <= validYear + 10 &&
          value !== null
          ? value
          : "";
      })
      .required("el año es requerido"),
    codigo: yup
      .string()
      .matches(cvcRegex, { message: "codigo invalido" })
      .when("tarjeta", {
        is: "amex",
        then: yup
          .string()
          .min(4, "El codigo debe tener 4 digitos si es American express")
          .max(4, "El codigo debe tener 4 digitos si es American express"),
        otherwise: yup
          .string()
          .min(3, "El codigo debe tener 3 digitos")
          .max(3, "El codigo debe tener 3 digitos"),
      })
      .trim()
      .required("el codigo es requerido"),
    cuotas: yup.string().when("tarjeta", {
      is: "visa_debito",
      then: yup.string(),
      otherwise: yup.string().required("La cantidad de cuotas es requerida"),
    }),
    nombre: yup
      .string()
      .matches(nameRegex, { message: "Ingrese nombre y apellido" })
      .trim()
      .required("El nombre es requerido"),
    documentoTipo: yup
      .string()
      .oneOf(["dni", "ci", "le", "lc"])
      .required("El tipo de documento es requerido"),
    documentoNumero: yup
      .string()
      .matches(numberRegex, { message: "Solo numeros" })
      .min(7, "El numero de documento debe tener como minimo 7 digitos")
      .max(10, "El numero de documento debe tener como maximo 8 digitos")
      .trim()
      .required("El numero de documento es requerido"),
    correo: yup
      .string()
      .email("Ingrese un correo valido")
      .trim()
      .required("El correo es requerido"),
    telefono: yup
      .string()
      .matches(phoneRegex, { message: "Ingrese un telefono valido" })
      .trim()
      .required("El telefono es requerido"),
    /* terminos: yup
      .boolean()
      .oneOf([true], "Debe aceptar los terminos y condiciones")
      .required("Debe aceptar los terminos y condiciones"), */
  });

  useEffect(() => {
    const turnCard = () => {
      setFocus("cvc");
      setCargaInicial(false);
    };
    setTimeout(turnCard, 500);

    const turnCardOver = () => {
      setFocus("");
    };
    setTimeout(turnCardOver, 1400);
  }, []);

  const handleTurnCard = () => {
    focus === "cvc" ? setFocus("") : setFocus("cvc");
  };

  useEffect(() => {
    var url = `https://store.autoprana.com.ar/pranaapi/tarjetas/tarjetas_cuotas`;

    if (tarjeta !== null) {
      fetch(url)
        .then((data) => {
          return data.json();
        })
        .then((modelos) => {
          let data = modelos.data; // your array
          let lastElement = data.pop();
          data.unshift(lastElement);
          modelos.data = data;
          console.log(modelos.data, "cuotas");
          setCuotas(modelos.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tarjeta]);

  const handleCuotasPorTarjeta = (product) => {
    if (
      cuotas &&
      product.origen !== "ventas" &&
      tarjeta !== "amex" &&
      tarjeta !== "naranja"
    ) {
      return cuotas.map((cuota, i) => (
        <option key={i} value={cuota.cuota}>
          {cuota.descripcion},{" "}
          {Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(
            cuota.interes === 0
              ? product.precio
              : product.precio * cuota.interes
          )}
          {/* {" "}
          - sin impuestos{" "}
          {Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(
            cuota.interes === 0
              ? product.precio / 1.21
              : (product.precio * cuota.interes) / 1.21
          )} */}
        </option>
      ));
    } else {
      return (
        cuotas &&
        cuotas.map(
          (cuota, i) =>
            cuota.interes !== 0 && (
              <option key={i} value={cuota.cuota}>
                {cuota.descripcion},{" "}
                {Intl.NumberFormat("es-AR", {
                  style: "currency",
                  currency: "ARS",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(product.precio * cuota.interes)}
                {/* {" "}
                - sin impuestos{" "}
                {Intl.NumberFormat("es-AR", {
                  style: "currency",
                  currency: "ARS",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format((product.precio * cuota.interes) / 1.21)} */}
              </option>
            )
        )
      );
    }
  };

  const handleTarjetaSeleccionada = (tarjetaSeleccionada) => {
    setTarjeta(tarjetaSeleccionada.value);
    setMethodId(tarjetaSeleccionada.method_id);
  };

  const onSubmit = async (values, actions) => {
    window.dataLayer.push({ event: "compra_0km" });

    tarjeta === "visa_debito" && (values.cuotas = "1");
    values.method_id = methodId;
    switch (product.sucursal) {
      case "P. Calderon de la Barca 3036":
        product.sucursal = "CA30";
        break;
      case "Lavallol 3331":
        product.sucursal = "LL33";
        break;

      default:
        break;
    }

    product = { ...product, datosDePago: values };

    console.log(product, "product final");

    Swal.fire({
      title: "Procesando pago...",
      html: "Espere un momento por favor.",
      timer: paymentStutus,
      timerProgressBar: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const url = `https://store.autoprana.com.ar/pranaapi/procesar`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(product),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    console.log(data.status);

    if (data.status === "ok") {
      console.log("enviado correctamente");
      setPaymentStatus(true);
      Swal.fire({
        icon: "success",
        title: "Pago procesado correctamente",
        text: "Gracias por su compra, recibira un email con los detalles de su compra",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
      });
      history.push("/");
    } else {
      console.log("error");
      setPaymentStatus(true);
      Swal.fire({
        icon: "error",
        title: "Error al procesar el pago",
        html: "verifique los datos ingresados y si cuenta con autorizacion de su tarjeta para realizar la compra en nuestro store. <br><br> <b>Visa</b> autorizaciones <b>4379-3400</b> <br> <b>Mastercard</b> autorizaciones <b>4348-7000</b> <br> <b>American Express</b> autorizaciones <b>0-810-555-2639</b> <br> <b>Naranja</b> autorizaciones <b>0-800-444-0836</b> <br>",
        /* text: "verifique los datos ingresados y si cuenta con autorizacion de su tarjeta para realizar la compra en nuestro store. <br> Visa autorizaciones 4379-3400 <br> Mastercard autorizaciones 4379-3401 <br> Amex autorizaciones 4379-3402 <br> Naranja autorizaciones 4379-3403 <br>", */
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
      });
    }

    /* actions.resetForm(); */
  };

  return {
    advanceSchema,
    tarjetas,
    tarjeta,
    onSubmit,
    numberRegex,
    justletterRegex,
    focus,
    handleTurnCard,
    setFocus,
    cargaInicial,
    cuotas,
    handleCuotasPorTarjeta,
    documentos,
    handleTarjetaSeleccionada,
  };
}
